/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Button } from 'components/buttons';
import { IconArrowDown } from 'components/Icon';

const ButtonStyled = styled(Button)`
    min-width: 200px;
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};

    border-radius: 25px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.grey[500]};
    &:hover:enabled {
        background-color: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.grey[300].hex};
    }
`;

const IconStyled = styled(IconArrowDown)`
    margin-left: ${({ theme }) => theme.spacing[1]};
`;

export { ButtonStyled, IconStyled };
