import React from 'react';
import { ButtonStyled, IconStyled } from './ScrollToButton.style';

const ScrollToButton = ({ children, className, scrollToHandler, buttonRef }) => (
    <ButtonStyled buttonRef={buttonRef} onClick={() => scrollToHandler()} className={className}>
        {children}
        <IconStyled size="small" />
    </ButtonStyled>
);

export default ScrollToButton;
